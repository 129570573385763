import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useCollections() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [collections, setCollections] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const collectionsCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "Collection",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    collectionsCollection.aggregate([
      { $match :{ _partition: realmApp.currentUser.id} }, 
      { $sort: { _id: -1} }
    ]).then((fetchedCollections) => {
        setCollections(fetchedCollections);
      setLoading(false);
    });
    
  }, [collectionsCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(collectionsCollection, {
    onInsert: (change) => {
        setCollections((oldCollections) => {
        if (loading) {
          return oldCollections;
        }
        const idx =
          getItemIndex(oldCollections, change.fullDocument) ?? oldCollections.length;
        if (idx === oldCollections.length) {
          return addValueAtIndex(oldCollections, idx, change.fullDocument);
        } else {
          return oldCollections;
        }
      });
    },
    onUpdate: (change) => {
        setCollections((oldCollections) => {
        if (loading) {
          return oldCollections;
        }
        const idx = getItemIndex(oldCollections, change.fullDocument);
        return updateValueAtIndex(oldCollections, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
        setCollections((oldCollections) => {
        if (loading) {
          return oldCollections;
        }
        const idx = getItemIndex(oldCollections, change.fullDocument);
        return replaceValueAtIndex(oldCollections, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
        setCollections((oldCollections) => {
        if (loading) {
          return oldCollections;
        }
        const idx = getItemIndex(oldCollections, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldCollections, idx);
        } else {
          return oldCollections;
        }
      });
    },
  });

  // Given a draft todo, format it and then insert it
  const saveCollection = async (draftExhibition) => {
    draftExhibition._partition = realmApp.currentUser.id;
    try {
      await collectionsCollection.updateOne({_id: draftExhibition._id}, draftExhibition, { upsert: true });
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const getCollectionById = async (id) => {
    const current = await collectionsCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  const deleteCollection = async (id) => {
    await collectionsCollection.deleteOne({_id: id})
  }


  return {
    loading,
    collections,
    saveCollection,
    getCollectionById,
    deleteCollection
  };
}
