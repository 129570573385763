import { Table, ActionIcon, Group, Image, Center, Pagination } from '@mantine/core';
import { IconPencil } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useCollections } from '../hooks/useCollections'; 
import { useEffect, useState } from 'react';

function Collections() {
  const { collections } = useCollections();
  
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };

  useEffect(() => {
    setCurrentData(collections.slice((page - 1 ) * 15, (page - 1 ) * 15 + 15));
  }, [page, collections])

  const rows = currentData.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>{element._id.toString()}</td>
        <td>{element.type}</td>
        <td>{dateFromObjectId(element._id.toString()).toLocaleString()}</td>
        <td>
          {
            element.image && element.image.length > 0 && 
          <Image
            height={50}
            width={50}
            fit="contain"
            radius="md"
            src={element.image[0]}
          />
          }
        </td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/collections/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  return (
    <>
      <Table>
        <thead>
            <tr>
            <th>ID</th>
            <th>Name</th>
            <th />
            <th />
            </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      { currentData.length > 0 && 
        <Center>
          <Pagination 
          page={page} 
          onChange={setPage}
          total={
            Math.ceil(collections.length / 12)
          }
          /> 
        </Center>
      }
    </>
  );
  }
  
export default Collections;